import React from "react"

import AppLayout from "../../Components/AppLayout"
import Content from "../../Components/Content"
import { WORK_ID } from "../../constant"

const PROJECT_ID = WORK_ID.MARCHMELLOW

const Work = () => {
  return (
    <AppLayout>
      <Content>
        <Content.Header>Marchmellow</Content.Header>
        <Content.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Content.Paragraph>
        <Content.Image
          src={`/assets/work/${PROJECT_ID}/01.jpg`}
          height={"500px"}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <Content.Title>Overview</Content.Title>
        <Content.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Content.Paragraph>
        <Content.Title>Overview</Content.Title>
        <Content.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Content.Paragraph>
        <Content.Title>Overview</Content.Title>
        <Content.Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Content.Paragraph>
      </Content>
    </AppLayout>
  )
}

export default Work
